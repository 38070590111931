<template>
  <v-card class="rounded-xl" id="create">
    <v-btn
        style="position: absolute; top: 0; right: 0"
        @click="showValidation = true"
        elevation="2"
        fab
        color="#3ac47d"
        :disabled="!isValid"
    >
      <v-icon size="40" color="white"> mdi-chevron-left</v-icon>
    </v-btn>
    <v-card-text>
      <v-container fluid class="ma-0 pa-0">
        <v-row no-gutters>
          <v-col cols="6">
            <div class="widget-content">
              <div class="widget-content-wrapper">
                <div class="widget-content-left font-weight-bolder">
                  <v-card class="pa-0 ma-0" elevation="0">
                    <v-card-text
                        class="pa-0 ma-0"
                        style="font-size: 2.1em; line-height: 26px"
                    >
                      {{ $t("accounting.lang_invoiceRef") }} {{ invoiceRef }}
                    </v-card-text>
                  </v-card>
                </div>
                <!--<div class="widget-content-right">
                  <div class="widget-heading">
                    <v-btn fab color="white" elevation="2">
                      <font-awesome-icon :icon="['fal','file-pdf']" size="2x" style="color: #FF6969;"/>
                    </v-btn><br/>
                  </div>
                  <div class="widget-subheading text-center font-weight-bold text--darken-2">
                    Preview PDF
                  </div>
                </div>-->
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <v-row>
              <v-col cols="12" class="pt-0 pb-0">
                <v-card elevation="0">
                  <v-card-text
                      class="fsize-1 py-1"
                      v-if="loadingSystem || !system"
                  >
                    <p>
                      <v-skeleton-loader
                          type="text"
                          max-width="350"
                          height="20"
                      />
                    </p>
                    <p>
                      <v-skeleton-loader
                          type="text"
                          max-width="250"
                          height="20"
                      />
                    </p>
                    <p>
                      <v-skeleton-loader
                          type="text"
                          max-width="150"
                          height="20"
                      />
                    </p>
                    <p>
                      <v-skeleton-loader
                          type="text"
                          max-width="220"
                          height="20"
                      />
                    </p>
                    <p>
                      <v-skeleton-loader
                          type="text"
                          max-width="260"
                          height="20"
                      />
                    </p>
                    <p>
                      <v-skeleton-loader
                          type="text"
                          max-width="300"
                          height="20"
                      />
                    </p>
                  </v-card-text>
                  <v-card-text class="fsize-1 py-1" v-else>
                    <p v-if="system.settings_SystemName">
                      {{ system.settings_SystemName }}
                    </p>
                    <p
                        v-if="
                        system.systemContactPreName ||
                        system.systemContactLastName
                      "
                    >
                      {{
                        system.systemContactPreName &&
                        system.systemContactLastName
                            ? (system.systemContactSalutation
                                ? system.systemContactSalutation
                                : " ") +
                            system.systemContactPreName +
                            " " +
                            system.systemContactLastName
                            : (system.systemContactSalutation
                                ? system.systemContactSalutation
                                : " ") + system.systemContactPreName ||
                            system.systemContactLastName
                      }}
                    </p>
                    <p v-if="system.settings_streetAndNumber">
                      {{ system.settings_streetAndNumber }}
                    </p>
                    <p v-if="system.settings_zip || system.settings_city">
                      {{
                        system.settings_zip && system.settings_city
                            ? system.settings_zip + " " + system.settings_city
                            : system.settings_zip || system.settings_city
                      }}
                    </p>
                    <p v-if="system.systemPhoneNo || system.systemFaxNo">
                      {{
                        system.systemPhoneNo && system.systemFaxNo
                            ? system.systemPhoneNo + "/" + system.systemFaxNo
                            : system.systemPhoneNo || system.systemFaxNo
                      }}
                    </p>
                    <p v-if="system.systemEmail">{{ system.systemEmail }}</p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <v-card elevation="0">
                  <v-card-text class="fsize-1 font-weight-bolder py-1">
                    {{ $t("accounting.lang_billTo") }}:
                    <v-btn
                        class="ma-0"
                        color="#3ac47d"
                        dark
                        rounded
                        elevation="0"
                        small
                        @click="showCreateCustomerDialog = true"
                    >
                      <span class="font-weight-bolder">
                        {{ $t("accounting.lang_setCustomer") }}
                      </span>
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" class="pt-0 pb-0 mb-2">
                <v-card elevation="0" v-if="customer">
                  <v-card-text class="fsize-1 py-1">
                    <p v-if="customer.company">{{ customer.company }}</p>
                    <p v-if="customer.first_name || customer.last_name">
                      {{
                        customer.first_name && customer.last_name
                            ? customer.first_name + " " + customer.last_name
                            : customer.first_name || customer.last_name
                      }}
                    </p>
                    <p v-if="customer.street || customer.streetNumber">
                      {{
                        customer.street && customer.streetNumber
                            ? customer.street + " " + customer.streetNumber
                            : customer.street || customer.streetNumber
                      }}
                    </p>
                    <p v-if="customer.city || customer.zip">
                      {{
                        customer.city && customer.zip
                            ? customer.city + " " + customer.zip
                            : customer.city || customer.zip
                      }}
                    </p>
                    <p v-if="customer.mobile">{{ customer.mobile }}</p>
                    <p v-if="customer.email">{{ customer.email }}</p>
                  </v-card-text>
                </v-card>
              </v-col>

              <!--start shipTo-->
              <v-col class="pt-0 pb-0" cols="12">
                <v-card elevation="0" v-if="customer && customer.hasShippingContact">
                  <v-card-text class="fsize-1 font-weight-bolder py-1">
                    {{ $t("accounting.lang_shipTo") }}:
                    <v-btn
                        class="ma-0"
                        color="#3ac47d"
                        dark
                        rounded
                        elevation="0"
                        small
                        @click="showShippingContactDialog = true"
                    >
                      <span class="font-weight-bolder">
                        {{ $t("accounting.lang_setCustomer") }}
                      </span>
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" class="pt-0 pb-0 mb-2">
                <v-card elevation="0" v-if="shippingContact && (customer && customer.hasShippingContact)">
                  <v-card-text class="fsize-1 py-1">
                    <p v-if="shippingContact.company">{{ shippingContact.company }}</p>
                    <p v-if="shippingContact.first_name || shippingContact.last_name">
                      {{
                        shippingContact.first_name && shippingContact.last_name
                            ? shippingContact.first_name + " " + shippingContact.last_name
                            : shippingContact.first_name || shippingContact.last_name
                      }}
                    </p>
                    <p v-if="shippingContact.street || shippingContact.streetNumber">
                      {{
                        shippingContact.street && shippingContact.streetNumber
                            ? shippingContact.street + " " + shippingContact.streetNumber
                            : shippingContact.street || shippingContact.streetNumber
                      }}
                    </p>
                    <p v-if="shippingContact.city || shippingContact.zip">
                      {{
                        shippingContact.city && shippingContact.zip
                            ? shippingContact.city + " " + shippingContact.zip
                            : shippingContact.city || shippingContact.zip
                      }}
                    </p>
                    <p v-if="shippingContact.mobile">{{ shippingContact.mobile }}</p>
                    <p v-if="shippingContact.email">{{ shippingContact.email }}</p>
                  </v-card-text>
                </v-card>
              </v-col>
              <!--end shipTo-->
            </v-row>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <v-select
                v-model="headerTemplate"
                :items="headerTemplates"
                :label="$t('settings.lang_headerTextTempl')"
                class="rounded-lg mb-2"
                return-object
                :value="standardHeaderTemplate"
                @change="fillTemlpate($event, 'header')"
                item-text="tmplName"
                style="border-color: transparent !important"
                outlined
                filled
                dense
                hide-details
            ></v-select>
            <v-textarea
                rows="8"
                :label="$t('generic.lang_text')"
                v-model="headerText"
                class="rounded-lg mb-2"
                style="border-color: transparent !important"
                outlined
                no-resize
                filled
                dense
                hide-details
            >
            </v-textarea>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <v-text-field
                class="mb-3"
                hide-details
                outlined
                :label="$t('accounting.lang_invoiceRef') + ' #'"
                v-model="invoiceRef"
                :dense="true"
            >
            </v-text-field>
            <date-picker
                class="mb-3"
                :label="$t('accounting.lang_invoiceDue')"
                :dense="true"
                :rules="[(v) => !!v || $t('generic.lang_requiredField')]"
                v-model="invoiceDue"
                :hide-details="true"
            />
            <v-select
                class="mb-3"
                :label="$t('accounting.lang_paymenthod')"
                v-model="paymentMethod"
                dense
                :items="filteredPayments"
                :rules="[(v) => !!v || $t('generic.lang_requiredField')]"
                item-value="paymentID"
                item-text="paymentName"
                outlined
                hide-details
            >
            </v-select>
            <date-picker
                class="mb-3"
                :label="$t('accounting.lang_performanceDate')"
                :dense="true"
                :rules="[(v) => !!v || $t('generic.lang_requiredField')]"
                v-model="performanceDate"
                :hide-details="true"
            />
          </v-col>
        </v-row>

        <!-- Inoice Items -->

        <v-row>
          <v-card elevation="0" rounded width="100%">
            <v-divider class="pa-0 ma-0 mt-2"/>
            <v-card-text class="pa-1">
              <v-row no-gutters>
                <v-col align="end">
                  <v-btn
                      elevation="0"
                      color="primary"
                      dark
                      outlined
                      @click="addItem()"
                  >
                    {{$t('generic.lang_addItem')}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="pa-0 ma-0"/>
            <v-card-text class="pa-1">
              <v-data-table
                  class="responsive-table"
                  :items="invoiceItems"
                  :headers="dataTableHeaders"
                  :loading="loading"
              >
                <template v-slot:loading>
                  <v-divider class="ma-0"/>
                </template>
                <template v-slot:body="{ items }">
                  <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td class="pa-1 text-center">{{ item.no + 1 }}</td>
                    <td class="pa-1">
                      <v-row no-gutters align="center">
                        <v-col align-self="center">
                          <v-autocomplete
                              clearable
                              @click:clear="clearItem(item)"
                              hide-details
                              outlined
                              dense
                              flat
                              class="mx-1"
                              :loading="item.loading"
                              type="text"
                              v-model="item.originalItem"
                              :items="
                                item.originalItem
                                  ? [item.originalItem]
                                  : filteredItems
                              "
                              item-text="name"
                              :filter="itemsFilter"
                              @change="selectItem($event, item)"
                              return-object
                              @keyup="searchItem($event, item)"
                              @blur="item.loading = false"
                          >
                            <template v-slot:prepend-item>
                              <v-list-item :disabled="isHybrid" >
                                <v-list-item-content
                                    @click="showAddItemDialog(item)"
                                >
                                  <v-list-item-title>
                                    <p style="cursor: pointer">
                                      {{ $t("generic.lang_add") }}
                                    </p>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col v-if="item.depositgroupID>0 && item.depositgroup" cols="12" align-self="center" class="pt-2">
                          <v-chip>{{ item.depositgroup.name }}</v-chip>
                        </v-col>
                      </v-row>
                    </td>
                    <td class="pa-1">
                      <v-row no-gutters align="center">
                        <v-col align-self="center">
                          <v-text-field
                              hide-details
                              outlined
                              dense
                              flat
                              class="mx-1"
                              type="text"
                              v-model="item.description"
                          />
                        </v-col>
                      </v-row>
                    </td>
                    <td class="pa-1">
                      <v-row no-gutters align="center">
                        <v-col align-self="center">
                          <v-text-field
                              hide-details
                              outlined
                              dense
                              flat
                              :suffix="item.id !== 0 ? item.unit : ''"
                              class="mx-1 number-input"
                              type="number"
                              min="0"
                              v-model="item.amount"
                              @input="calculateTotal(item)"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </td>
                    <td class="pa-1">
                      <v-row no-gutters align="center">
                        <v-col align-self="center">
                          <v-text-field
                              hide-details
                              outlined
                              dense
                              flat
                              class="mx-1 number-input"
                              type="number"
                               :disabled="settings.settings.factureGrossPrice==='1'"
                              v-model="item.price"
                              @input="priceInput(item,'net')"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </td>
                    <td class="pa-1">
                      <v-row no-gutters align="center">
                        <v-col align-self="center">
                          <v-text-field hide-details
                                        outlined
                                        dense
                                        flat
                                        class="mx-1 number-input"
                                        type="number"

                                        :disabled="settings.settings.factureGrossPrice==='0'"
                                        v-model="item.sellPrice"
                                        @input="priceInput(item,'gross')"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </td>
                    <td class="pa-1">
                      <v-row no-gutters align="center">
                        <v-col align-self="center">
                          <v-text-field
                              hide-details
                              outlined
                              dense
                              class="mx-1 number-input"
                              flat
                              type="number"
                              min="0"
                              v-model="item.discount"
                              @input="discountInput(item)"
                          />
                        </v-col>
                      </v-row>
                    </td>
                    <td class="pa-1">
                      <v-row no-gutters align="center">
                        <v-col align-self="center">
                          <v-select
                              hide-details
                              outlined
                              dense
                              flat
                              class="mx-1"
                              :items="tax"
                              item-value="value"
                              item-text="name"
                              v-model="item.taxValue"
                              @change="taxInput(item)"
                          />
                        </v-col>
                      </v-row>
                    </td>

                    <td class="pa-1">
                      {{ item.total | currency }}
                    </td>
                    <td class="pa-1">
                      <v-btn
                          fab
                          color="error"
                          class="ma-0"
                          dark
                          x-small
                          elevation="3"
                          @click="deleteItem(item)"
                      >
                        <v-icon :size="15">mdi-trash-can</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card-text>
            <v-divider class="ma-0"/>
            <v-card-text class="pa-1">
              <v-row no-gutters>
                <v-col align="end">
                  <v-btn
                      elevation="0"
                      color="primary"
                      dark
                      outlined
                      @click="addItem()"
                  >
                    {{ $t("generic.lang_add") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider class="pa-0 ma-0 mb-2"/>
          </v-card>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="6" lg="6" xl="4">
            <v-card class="rounded-lg mb-2" elevation="0" :color="this.$vuetify.theme.dark? '#2E2E2E' : '#f0f0f0'">
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="6">
                    <span>{{ $t("erp.lang_ware_net") }}</span>
                  </v-col>
                  <v-col cols="6" align="right">
                    <span>{{ totalAfterDiscount | currency }}</span>
                  </v-col>
                  <v-col cols="12">
                    <v-divider class="ma-2"/>
                  </v-col>
                  <v-col cols="6">
                    <span>{{ $t("generic.lang_subtotal")+' '+$t("generic.lang_discount") }}</span>
                  </v-col>
                  <v-col cols="6" align="right">
                    <template v-for="(discount, index) in discountRate">
                      <div :key="index">
                        <span>{{ discount.rate }}% </span>
                        <span>{{ discount.amount | currency }}</span>
                      </div>
                    </template>
                    <v-divider v-if="discountRate.length > 1" class="ma-1"/>
                    <span v-if="discountRate.length > 1"
                    >{{ totalDiscount | currency }}
                    </span>
                  </v-col>
                  <v-col cols="12">
                    <v-divider class="ma-2"/>
                  </v-col>
                  <v-col cols="6">
                    <span>{{ $t("accounting.lang_taxes") }}</span>
                  </v-col>
                  <v-col cols="6" align="right">
                    <template v-for="(tax, index) in taxRate">
                      <div :key="index">
                        <span>{{ tax.rate }}% </span>
                        <span>{{ tax.amount | currency }}</span>
                      </div>
                    </template>
                    <v-divider v-if="taxRate.length > 1" class="ma-1"/>
                    <span v-if="taxRate.length > 1"
                    >{{ totalTax | currency }}
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text>
                <v-row no-gutters>
                  <v-col
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      align-self="center"
                      align="center"
                  >
                    <v-text-field
                        type="number"
                        v-model="discount"
                        :label="$t('generic.lang_overall_discount')"
                        @input="discountAll($event)"
                    />
                  </v-col>
                </v-row>
                <v-divider class="my-0"/>
                <v-row no-gutters>
                  <v-col sm="12" md="12" lg="12" xl="12">
                    <p class="ma-1 font-weight-light" :style="this.$vuetify.theme.dark? '' : 'color:#515C6F;'">
                      {{ $t("erp.lang_ware_total") }}
                    </p>
                    <p
                        class="ma-1 fsize-3 font-weight-bold"
                        :style="this.$vuetify.theme.dark? '' : 'color:#515C6F;'"
                    >
                      {{ total | currency }}
                    </p>
                    <!--- <p class="ma-1" style="color:#515C6F;">CASH</p> -->
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <!--<v-row>
             <v-col cols="12">
               <v-select :label="'Delivery Term'" dense  outlined hide-details>
               </v-select>
             </v-col>
           </v-row>-->
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" xl="8">
            <v-select
                v-model="conditionsTemplate"
                :items="conditionsTemplates"
                :label="$t('generic.lang_ConditionsBody')"
                class="rounded-lg mb-2"
                return-object
                :value="standardConditionsTemplate"
                @change="fillTemlpate($event, 'conditions')"
                item-text="tmplName"
                style="border-color: transparent !important"
                outlined
                no-resize
                filled
                dense
                hide-details
            ></v-select>
            <v-textarea
                rows="8"
                :label="$t('generic.lang_text')"
                v-model="conditions"
                class="rounded-lg mb-2"
                style="border-color: transparent !important"
                outlined
                no-resize
                filled
                dense
                hide-details
            >
            </v-textarea>
            <v-textarea
                rows="4"
                :label="$t('generic.lang_terms2') + ':'"
                v-model="terms"
                class="rounded-lg"
                style="border-color: transparent !important"
                outlined
                no-resize
                filled
                dense
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <CustomerSearchComponent
        :show-dialog="showCreateCustomerDialog"
        @close="showCreateCustomerDialog = false"
        @selectCustomer="setCustomerData($event,true)"
    />
    <ShippingContactSearchComponent
        :show-dialog="showShippingContactDialog"
        @close="showShippingContactDialog = false"
        :parentCustomerId="customer && customer.id"
        @selectShippingContact="setShippingContactData($event,true)"
    />
    <CreateItemDialog
        :show-dialog="showCreateItemDialog"
        @close="showCreateItemDialog = false"
        @selectItem="setItem"
    />
    <BillingPDFViewerComponent
        :drawer="showValidation"
        @close="showValidation = false"
        @saveAsDraft="saveInvoiceAsDraft"
        :show-pdf="false"
        @book="commit"
        @pay="commit"
        :pay="paymentMethod === 1 || paymentMethod === 3"
        :loading="loading"
    />
  </v-card>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import DatePicker from "@/components/common/datepicker";

import {faBadgePercent, faFilePdf} from "@fortawesome/pro-light-svg-icons";
import CustomerSearchComponent from "@/components/billing/CustomerSearchComponent";
import ShippingContactSearchComponent from "@/components/billing/ShippingContactSearchComponent";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import CreateItem from "@/components/erp/baseData/CreateItem";
import CreateItemDialog from "@/components/billing/CreateItemDialog";
import BillingPDFViewerComponent from "@/components/billing/BillingPDFViewerComponent";
import moment from "moment";
import factureMixin from "@/mixins/facture/factureMixin";

library.add(faFilePdf, faBadgePercent);

export default {
  name: "CreateInvoiceComponent",
  components: {
    BillingPDFViewerComponent,
    CreateItemDialog,
    CreateItem,
    CustomerSearchComponent,
    ShippingContactSearchComponent,
    "font-awesome-icon": FontAwesomeIcon,
    DatePicker,
  },
  mixins:[factureMixin],
  data() {
    return {

    };
  },
  computed: {
    standardHeaderTemplate() {
      if (this.conditionsTemplate == null) {
        this.headerTemplates.forEach((element) => {
          if (element.standard == 1) {
            this.headerTemplate = element;
            this.fillTemlpate(this.headerTemplate, 'header');
            return;
          }
        });
      }

      return this.headerTemplate;
    },
    standardConditionsTemplate() {
      if (this.conditionsTemplate == null) {
        this.conditionsTemplates.forEach((element) => {
          if (element.standard == 1) {
            this.conditionsTemplate = element;
            this.fillTemlpate(this.conditionsTemplate, 'conditions');
            return;
          }
        });
      }
      
      return this.conditionsTemplate;
    },
  },
  methods: {
    fillTemlpate(template, target) {
      if (target === "header") {
        this.headerText = template.tmplText;
      } else {
        this.conditions = template.tmplText;
      }
    },
    saveInvoiceAsDraft() {
      let validItems = this.validItems.map((item) => {
        const {originalItem, ...obj} = item;
        return obj;
      });
      this.loading = true;
      const {group, itemgroupDiscount, ...customerData} = this.customer
          ? this.customer
          : {};

      this.axios
          .post(ENDPOINTS.BILLING.DRAFT.CREATBILLDRAFT, {
            customerId: this.customer ? this.customer.id : 0,
            customerData: customerData,
            shippingCustomerId: this.shippingContact ? this.shippingContact.id : 0,
            shippingData: this.shippingContact,
            invoiceDraftObj: validItems,
            invoiceDraftDueTimeStamp: moment(this.invoiceDue).unix(),
            performanceDate: moment(this.performanceDate).unix(),
            invoiceDraftHeader: this.headerText,
            invoiceDraftConditions: this.conditions,
            invoiceDraftTerms: this.terms,
            invoiceDraftRef: this.invoiceRef,
            paymentTypeID: this.paymentMethod,
            total: this.total,
          })
          .then((res) => {
            if (res.data.status === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_savedAsDraft'),
                color: "success",
              });
              this.$router.push({
                name: "billing.drafts.edit",
                params: {uuid: res.data.uuid},
              });
            } else {
              Events.$emit("showSnackbar", {
                message: res.data.msg || res.data.status,
                color: "error",
              });
            }
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              message: err.message,
              color: "error",
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    commit() {
      let validItems = this.validItems.map((item) => {
        const {originalItem, ...obj} = item;
        return obj;
      });
      this.loading = true;
      let postData = {
        saleAction: "CASHOUT",
        posVersion: "FACTURE",
        conditions: this.conditions,
        terms: this.terms,
        headerText: this.headerText,
        paymentType: this.paymentMethod,
        invoiceDraftDueTimeStamp: moment(this.invoiceDue).unix(),
        performanceDate: moment(this.performanceDate).unix(),
        globalFreeText: "",
        totalPrice: this.total,
        goodsValue: this.total,
        progressToken: this.pos.progressToken,
        OrderArray: validItems,
        newOrderArray: [],
        moneyGiven: this.total,
        invoiceReference: this.invoiceRef,
      };

      if (this.customer) {
        postData.customerID = this.customer.id;
      }
      if (this.shippingContact) {
        postData.shippingCustomerId = this.shippingContact.id;
      }

      this.axios
          .post(ENDPOINTS.POS.SALE.CREATE, postData)
          .then((res) => {
            if (res.data.status === "PROGRESS_TOKEN") {
              // UPDATE PROGRESS TOKEN
              this.$store.commit(
                  "pos/setProgressToken",
                  res.data.apiProgressToken
              );

              this.commit();
            } else if (res.data.status === "SUCCESS") {
              // UPDATE PROGRESS TOKEN
              this.$store.commit(
                  "pos/setProgressToken",
                  res.data.apiProgressToken
              );

              //this.invoiceResultData = res.data;

              this.$router.push({
                name: "accounting.DisplayInvoice",
                params: {id: res.data.invoiceUUID},
              });
            } else {
              this.loading = false;
            }
          })
          .catch((err) => {
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
  mounted() {
    this.getTaxes();
    this.getUnits();
    this.getSystemInfo();
    this.getTextTemplates();

    //add first item
    this.addItem();
  },
};
</script>

<style scoped>
.text-start {
  padding: 1px !important;
}

#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}

p {
  margin-bottom: 4px;
}

/* Chrome, Safari, Edge, Opera */
.number-input input::-webkit-outer-spin-button,
.number-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.number-input input[type="number"] {
  -moz-appearance: textfield;
}
</style>
