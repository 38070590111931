<template>
<v-container class="ma-0 pa-0" fluid>
<v-card elevation="0" outlined>
  <v-card-text class="ma-0 pa-0">
    <v-card elevation="0">
      <v-card-title class="pa-0 ma-0">
        <v-row>
          <v-col class="pt-2 ma-0" cols="12">
            <div class="card-header-tab card-header">
              <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                <b-input-group width="100%">
                  <b-form-input :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" v-model="search"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :placeholder="$t('datatables.lang_search')"
                                @focus="showTouchKeyboard"/>
                </b-input-group>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-container>
        <v-row dense>
          <v-col cols="6">
            <date-picker @input="getDataFromApi" v-model="filterFields.startDate.value" :label="$t('generic.lang_from')" hide-details outlined clearable dense  >

            </date-picker>
          </v-col>
          <v-col cols="6">
            <date-picker @input="getDataFromApi" v-model="filterFields.endDate.value" :label="$t('generic.lang_till')"  hide-details outlined clearable dense  >

            </date-picker>
          </v-col>
          <v-col cols="6">
          <v-autocomplete @change="getDataFromApi" v-model="filterFields.customer.value" hide-details :label="$t('customers.lang_customers')" clearable clear-icon="mdi-close" outlined  :items="showCustomers"
                          item-value="id" item-text="full_name" dense>

            </v-autocomplete>
          </v-col>
          <v-col  cols="6">
            <v-select @change="getDataFromApi" v-model="filterFields.paymentID.value" hide-details :label="$t('accounting.lang_paymenthod')" clearable clear-icon="mdi-close" outlined dense  :items="filteredPayments" item-value="paymentID" item-text="paymentName">

            </v-select>
          </v-col>
          <v-col cols="6">
            <v-select v-model="filterFields.status.value" :label="$t('generic.lang_status')" @change="getDataFromApi" hide-details clearable clear-icon="mdi-close" outlined dense  :items="[{name:'Paid',status:'1'},{name:'Not Paid',status:'0'}]" item-value="status" item-text="name">

            </v-select>
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="ma-1"/>
      <v-data-table
          v-model="selectedRows"
          :footer-props="footerProps"
          :headers="filteredDatatableHeaders"
          :items="filteredDatatableEntries"
          :loading="loading"
          :options.sync="pagination"
          :server-items-length="total"
          :show-select="true"
          footer-props.items-per-page-text="Einträge pro Seite"
      >
        <template v-slot:item.total="{ item }">
          {{ item.total | currency }}
        </template>
        <template v-slot:item.invoiceDueTimeStamp="{ item }">
          {{getDate(item.invoiceDueTimeStamp)}}
        </template>
        <template v-slot:item.status="{ item }">
          {{ (item.status===1)? $t('generic.lang_paid') : $t('generic.lang_notPaid') }}
        </template>
        <template v-slot:item.crud="{ item }">
          <v-row align="center" justify="center">
            <v-btn class="mx-auto" color="primary" icon text @click="editEntry(item)">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-table>
    </v-card>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"/>
    </div>
  </v-card-text>
</v-card>
</v-container>
</template>

<script>
import {ENDPOINTS} from "../../config";
import {mapState} from 'vuex'
import {Events} from "../../plugins/events";
import mixin from "../../mixins/KeyboardMixIns"
import moment from 'moment'
import DatePicker from "@/components/common/datepicker";
/*
itemIDs
reference
customer
date
paymentID
status
*/
export default {
  name: "InvoicesComponent",
  components: {DatePicker},
  mixins: [mixin],
  data() {
    return {
      //array('invoiceUUID', 'invoiceSaleID', 'saleTimeStamp', 'sellerID', 'customerData', 'itemQuantity', 'invoiceTotal', 'typeOfPayment'
      // , 'tableName', 'invoiceAufsHaus', 'cashierID', 'invoiceType','invoiceDueTimeStamp','invoicePaidTimeStamp','invoiceReference')
      datatableHeaders: [
        {
          text: 'UUID',
          align: 'left',
          hide: true,
          value: 'uuid'
        },
        {
          text: 'Id',
          align: 'left',
          value: 'id'
        },
        {
          text: this.$t('generic.lang_date'),
          align: 'left',
          value: 'date',
        },
        {
          text: this.$t('generic.lang_seller'),
          align: 'left',
          value: 'seller'
        },
        {
          text: this.$t('generic.lang_customer'),
          align: 'left',
          value: 'customer',
        },
        {
          text: this.$t('erp.lang_totalQTY'),
          align: 'left',
          value: 'itemsQTY'
        },
        {
          text: this.$t('generic.lang_total'),
          align: 'left',
          value: 'total'
        },
        {
          text: this.$t('accounting.lang_paymenthod'),
          align: 'left',
          value: 'payment'
        },
        {
          text: 'tableName',
          align: 'left',
          hide: true,
          value: 'tableName'
        },
        {
          text: 'invoiceAufsHaus',
          align: 'left',
          hide: true,
          value: 'invoiceAufsHaus'
        },
        {
          text: 'cashierID',
          align: 'left',
          hide: true,
          value: 'cashierID'
        },
        {
          text: 'invoiceType',
          align: 'left',
          hide: true,
          value: 'invoiceType'
        },
        {
          text: this.$t('accounting.lang_invoiceDue'),
          align: 'left',
          value: 'invoiceDueTimeStamp'
        },
        {
          text: 'invoicePaidTimeStamp',
          align: 'left',
          value: 'invoicePaidTimeStamp',
          hide:true,
        },
        {
          text: this.$t('generic.lang_status'),
          align: 'left',
          value: 'status'
        },
        {
          text: '',
          value: 'crud',
          sortable: false,
          width: '230',
          align: 'end',
        },

      ],
      loading: false,
      selectedRows: [],
      awaitingSearch: null,
      dataToShow: [],
      search: '',
      total: 0,
      pagination: {},
      footerProps: {'items-per-page-options': [15, 30, 50, 100]},
      rowsPerPageItems: [10, 20, 50, 100],
      id: null,
      filterFields:{
          startDate:{index:'start_date',value:null},
          endDate:{index:'end_date',value:null},
          customer:{index:4,value:null},
          paymentID:{index:7,value:null},
          status:{index:14,value:null},
      },
      showCustomers:[],
    }
  },
  computed: {
    ...mapState([
        'payments'
    ]),
    filteredPayments(){
      return _.orderBy(this.payments.payments.filter(payment=>{
        return payment.paymentID!==7
      }),'paymentName')
    },
    filteredDatatableHeaders: function () {
      let headers = [];

      this.datatableHeaders.forEach((header) => {
        if (header.hasOwnProperty("hide")) {
          if (header.hide) {
            return;
          }
        }
        headers.push(header);
      });
      return headers;
    },
    filteredDatatableEntries: function () {
      let dataToRender = [];

      this.dataToShow.forEach((data) => {
        let row = {};

        this.datatableHeaders.forEach((header, index) => {
          if (!(header.hidden && header.hidden == true)) {
            row[header.value] = data[index];
          }

        });

        dataToRender.push(row);
      });

      return dataToRender;
    }
  },

  watch: {
    search: function () {
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        if (this.pagination.page !== 1) {
          this.pagination.page = 1;
        } else {
          this.getDataFromApi();
        }
      }, 500);
    },
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    selectedRows: function () {
      this.$emit("changeSelectedRows", this.selectedRows);
    }
  },
  methods: {
    getDate(timeStamp){
      return moment.unix(timeStamp).format('DD.MM.YY HH:mm')
    },
    editEntry(entry) {
      this.$router.replace({name: 'accounting.DisplayInvoice', params: {id: entry.uuid}})
    },
    resetSelectedRows() {
      this.selectedRows = [];
    },
    getDataFromApi() {
      this.loading = true;
      this.selectedRows = [];

      let params = {
        sEcho: 1,
        iDisplayStart: this.pagination.itemsPerPage * (this.pagination.page - 1),
        iDisplayLength: this.pagination.itemsPerPage
      };

      //SORTING / PAGINATION / SEARCHING
      if (this.search) {
        params.sSearch = this.search;
      }
      params["iSortingCols"] = 1;
      if (this.pagination.sortBy) {
        //FIND COL. INDEX
        let index = this.datatableHeaders.findIndex(header => header.value === this.pagination.sortBy[0]);

        if (index < 0) {
          index = 0;
        }

        params["iSortCol_0"] = index;
        params["bSortable_" + index] = "true";
        params["sSortDir_0"] = this.pagination.sortDesc[0] ? 'desc' : 'asc';
      }

      if(!this.pagination.sortBy[0]){
        params["iSortCol_0"] = 3;
        params["bSortable_" + 3] = "true";
        params["sSortDir_0"] = 'desc';
      }

      //PUT ADDITIONAL PARAMS HERE
      //params['customerID'] = parseInt(this.$props.customerId);
      if(this.filterFields.startDate.value){
        params[this.filterFields.startDate.index] = moment(this.filterFields.startDate.value).startOf('days').unix();
      }
      if(this.filterFields.endDate.value){
        params[this.filterFields.endDate.index] = moment(this.filterFields.endDate.value).endOf('days').unix();
      }

      for(const key in this.filterFields){

          if(this.filterFields[key].value){
            if(!isNaN(parseInt(this.filterFields[key].index))){
                params["bSearchable_"+this.filterFields[key].index] = "true";
                params["sSearch_"+this.filterFields[key].index] = this.filterFields[key].value;
            }
          }

      }


      this.axios.get(ENDPOINTS.DATATABLES.BILLING.INVOICES, {
        params: params
      }).then((res) => {
        this.total = parseInt(res.data.iTotalDisplayRecords);
        //let data=res.data.aaData;
        this.dataToShow = res.data.aaData;
        this.loading = false;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });

        this.dataToShow = [];
      });
    },
    loadCustomers() {


      //AXIOS CALL
      this.axios.get(ENDPOINTS.ERP.CUSTOMER.GET, {
        params: {
          search:"",
        }
      }).then((res) => {

        if (res.status === 200) {
          if (res.data.results.length > 0) {
            this.showCustomers = res.data.results;
          } else {
            this.showCustomers = [];
          }
        }
      }).catch(() => {

      });
    },
  },
  mounted() {
    this.getDataFromApi();
    this.loadCustomers()
  }
}
</script>
